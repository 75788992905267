import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Radio, Col, Input, notification, Row, Table, Tooltip } from 'antd'
import { navigate } from 'gatsby'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { isManager, isOwner } from '../../../api/auth'
import client from '../../../api/client'
import { CreateShipment } from '../../../components/admin'
import StatusTag from '../../../components/base/status'
import { SHIPMENT_TYPES } from '../../../constants/enum'
import { currencyFormatter } from '../../../constants/helper-functions'

const { Search } = Input

const defaultPaginatedRequestData = {
  search: '',
  pagination: { current: 1, pageSize: 10 },
  sorter: { field: 'date', order: 'ascend' },
  shipmentStatus: ['New', 'InProgress', 'Shipped', 'ArrivedInMongolia', 'ReadyForDelivery', 'Distributed'],
  includeContainerShipmentType: false,
}

const Shipments = () => {
  const [loading, setLoading] = useState(true)
  const [apiLoading, setApiLoading] = useState(false)
  const [createShipmentModalVisible, setCreateShipmentModalVisible] = useState(false)
  const [shipmentStatuses, setShipmentStatuses] = useState([])
  const [shipmentsInfo, setShipmentsInfo] = useState({
    tableData: [],
    dataCount: 0,
  })
  // const [paginatedRequestData, setPaginatedRequestData] = useState({
  //   search: '',
  //   pagination: { current: 1, pageSize: 2 },
  //   sorter: { field: 'date', order: 'ascend' },
  //   shipmentStatus: ['New', 'InProgress', 'Shipped', 'ArrivedInMongolia', 'ReadyForDelivery'],
  //   includeContainerShipmentType: false,
  // })

  const [paginatedRequestData, setPaginatedRequestData] = useState(defaultPaginatedRequestData)

  const shipmentColumns = [
    { title: 'Огноо', className: 'text-center', dataIndex: 'date', render: (date) => moment(date).format('MM/DD/YYYY') },
    { title: 'Салбар', className: 'text-center', dataIndex: 'branchName' },
    // {
    //   title: 'Тайлбар',
    //   className: 'text-center',
    //   dataIndex: 'description',
    //   render: (record) => <Tooltip title={record}>{record && record.length > 20 ? record.substring(0, 20) + '...' : record}</Tooltip>,
    // },
    {
      title: 'Илгээмжийн нийт жин',
      className: 'text-center',
      dataIndex: 'weight',
      render: (weight) => `${Number.isInteger(weight) ? weight : weight.toFixed(2)} кг`,
    },
    { title: 'Нийт багцын тоо', className: 'text-center', dataIndex: 'packageCount' },
    { title: 'Нийт төлбөр', className: 'text-center', dataIndex: 'totalAmount', render: (amount) => `${currencyFormatter(amount)}` },
    { title: 'АНУ-д', className: 'text-center', dataIndex: 'totalAmountInUSA', render: (amount) => `${currencyFormatter(amount)}` },
    { title: 'Монголд', className: 'text-center', dataIndex: 'totalAmountInMGL', render: (amount) => `${currencyFormatter(amount)}` },
    { title: 'Төрөл', className: 'text-center', dataIndex: 'shipmentType', render: (shipmentType) => SHIPMENT_TYPES[shipmentType] ?? '-' },
    { title: 'Статус', className: 'text-center', dataIndex: 'shipmentStatus', render: (status) => <StatusTag status={{ ...status }} /> },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      render: (record) => {
        return (
          <Row style={{ width: '100%', gap: '12px', minWidth: '100px' }} align={'middle'} justify={'center'}>
            <Tooltip title="Үзэх">
              <Button className="btn-sb btn-sb-primary " onClick={() => navigate(`/admin/shipments/${record.id}`)}>
                <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
              </Button>
            </Tooltip>
            <Tooltip title="Тайлан татах">
              <Button className="btn-sb btn-sb-primary" onClick={() => downloadReport(record.id)}>
                <FontAwesomeIcon icon={faDownload} size="sm" className="mx-2" />
              </Button>
            </Tooltip>
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    const savedData = sessionStorage.getItem('paginatedRequestData')
    if (savedData) {
      setPaginatedRequestData(JSON.parse(savedData))
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('paginatedRequestData', JSON.stringify(paginatedRequestData))
  }, [paginatedRequestData])

  useEffect(() => {
    client
      .get(`/api/lookup/shipmentstatuses`)
      .then((res) => {
        setShipmentStatuses(res.data.map((el) => ({ value: el.id, label: el.name, description: el.description })))
      })
      .catch((err) => console.error({ err }))
  }, [])

  useEffect(() => {
    client
      .post('/api/shipments/paginated', { ...paginatedRequestData })
      .then(async (res) => {
        setShipmentsInfo({
          tableData: res.data.data,
          dataCount: res.data.total,
        })
      })
      .catch((err) => console.error({ err }))
      .finally(() => setLoading(false))
  }, [paginatedRequestData])

  const createShipment = (values) => {
    setApiLoading(true)
    const data = {
      ...values,
      shipmentDate: moment(values.date).format('MM-DD-YYYY'),
      shipmentStatus: shipmentStatuses.find((x) => x.value === values.shipmentStatus).description ?? 'New',
    }

    const endpoint = '/api/shipments'
    client
      .post(endpoint, data)
      .then(async (res) => {
        setCreateShipmentModalVisible(false)
        notification.success({
          message: 'Богцыг амжилттай бүртгэлээ!',
          description: 'Богцын дэлгэрэнгүй хуудасруу шилжиж байна',
        })
        setTimeout(() => navigate(`/admin/shipments/${res.data.id}`), 1000)
      })
      .catch((err) => {
        console.error({ err })
        setApiLoading(false)
      })
  }

  const downloadReport = (id) => {
    client
      .get(`/api/admin/shipment/${id}/report`, { responseType: 'blob' })
      .then(async (res) => {
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `SBExpress_ShipmentReport_${id}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        notification.error({ message: 'Тайлан татаж авахад асуудал гарлаа', description: `${err}` })
        console.error(err)
      })
  }

  return (
    <>
      <Helmet title="Богц" defer={false} />
      <Row gutter={[8, 8]} className="mt-4">
        <Col flex="auto" style={tableTitleStyle}>
          <span>Богц ({shipmentsInfo.dataCount})</span>
        </Col>
        <Col flex="255px" style={{ marginLeft: '40px' }}>
          <Search
            className="sb-search sb-shadow"
            placeholder="Хайх"
            onSearch={(search) => {
              setLoading(true)
              setPaginatedRequestData({
                ...paginatedRequestData,
                search,
                pagination: {
                  ...paginatedRequestData.pagination,
                  current: 1,
                },
              })
            }}
          />
        </Col>
        {!isManager() && (
          <Col flex="230px" style={{ marginLeft: 40 }}>
            <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" onClick={() => setCreateShipmentModalVisible(true)}>
              Богц бүртгэх
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={16} className="mt-2">
          <Radio.Group
            defaultValue="All"
            onChange={(e) => {
              const types = e.target.value !== 'All' ? [e.target.value] : ['Ground', 'Air', 'Container', 'Express']
              setPaginatedRequestData({
                ...paginatedRequestData,
                shipmentTypes: types,
                pagination: {
                  ...paginatedRequestData.pagination,
                  current: 1,
                },
              })
            }}
          >
            <Radio value="Air">Агаарын ачаа</Radio>
            <Radio value="Ground">Газрын ачаа</Radio>
            <Radio value="Express">Express ачаа</Radio>
            {isOwner() ? <Radio value="Container">Чингэлэгийн ачаа</Radio> : []}
            <Radio value="All">Бүгд</Radio>
          </Radio.Group>
        </Col>
        {isOwner() ? (
          <Col span={4}>
            <Checkbox
              className="sb-checkbox"
              onChange={(e) => {
                setPaginatedRequestData({
                  ...paginatedRequestData,
                  includeContainerShipmentType: e.target.checked ? true : false,
                  pagination: {
                    ...paginatedRequestData.pagination,
                    current: 1,
                  },
                })
              }}
            >
              Чингэлэгийн ачаа харах
            </Checkbox>
          </Col>
        ) : (
          []
        )}
        <Col span={4}>
          <Checkbox
            className="sb-checkbox"
            onChange={(e) => {
              setPaginatedRequestData({
                ...paginatedRequestData,
                shipmentStatus: e.target.checked
                  ? ['Archived']
                  : ['New', 'InProgress', 'Shipped', 'ArrivedInMongolia', 'ReadyForDelivery', 'Distributed'],
                pagination: {
                  ...paginatedRequestData.pagination,
                  current: 1,
                },
              })
            }}
          >
            Архивласан богц харах
          </Checkbox>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Table
          className="sb-table w-100 mt-3"
          dataSource={shipmentsInfo.tableData}
          columns={shipmentColumns}
          rowKey={(record) => record.id}
          locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
          loading={loading}
          pagination={{
            total: shipmentsInfo.dataCount,
            current: paginatedRequestData.pagination.current,
            pageSize: paginatedRequestData.pagination.pageSize,
            onChange: async (page, pageSize) => {
              setLoading(true)
              setPaginatedRequestData({
                ...paginatedRequestData,
                pagination: { current: page, pageSize },
              })
            },
          }}
        />
      </Row>
      {createShipmentModalVisible && (
        <CreateShipment
          modalVisible={createShipmentModalVisible}
          closeModal={() => setCreateShipmentModalVisible(false)}
          apiLoading={apiLoading}
          shipmentStatuses={shipmentStatuses}
          createShipment={createShipment}
        />
      )}
    </>
  )
}

export default Shipments

const tableTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
}
